import React, { useEffect } from 'react';
import MaterialTable, { Column, Icons } from 'material-table';
import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
// import { useStyles } from '../common/tabsStyle';
import { Badge, Chip, IconButton, makeStyles, Typography } from '@material-ui/core';
// import { TaskflowDialog } from '../dialogs/TaskflowDialog';
import { useHistory, useLocation } from 'react-router-dom';
import { mainTheme } from '../../../Theme';
import gql from 'graphql-tag';
import { useQuery, useSubscription } from 'react-apollo';
import { CustomFieldGroupDialog } from '../../dialogs/CustomFieldGroupDialog';
import { client } from '../../..';
import { showNotification } from '../../../App';
import CheckIcon from '@material-ui/icons/Check';
import { CustomFieldPreviewDialog } from '../../dialogs/CustomFieldPreviewDialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';

const useStyles = makeStyles({
    root: {
        '& .MuiSvgIcon-root': {
            color: '#5173FF'
        },
        '& .MuiTypography-h6': {
            color: mainTheme.BrandColors.TemplatePrimary
        },

        '& .MuiTable-root': {
            display: 'flex',
            flexFlow: 'column',
            '& .MuiTableCell-root': {
                '& .MuiIconButton-colorInherit': {
                    color: '#5173FF !important'
                },
            },
            '& thead.MuiTableHead-root': {
                display: 'flex',
                flex: 1,
                '& tr.MuiTableRow-head': {
                    display: 'flex',
                    flex: 1,
                    alignItems: 'stretch',
                    '& th.MuiTableCell-root': {
                        padding: '0 10px',
                    }
                },
                '& th.MuiTableCell-paddingNone': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 'auto !important',
                },
                '& th.MuiTableCell-paddingCheckbox': {
                    width: 'auto !important',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: '100px',
                },
                '& th.MuiTableCell-alignLeft': {
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    width: 'auto !important'
                },
            },
            '& tbody.MuiTableBody-root': {
                flex: 1,
                display: 'flex',
                flexFlow: 'column',
                '& tr.MuiTableRow-root': {
                    display: 'flex',
                    flex: 1,
                    alignItems: 'stretch',
                    height: 'auto !important',
                },
                '& .MuiTableCell-root': {
                    width: '100%',
                    padding: '0 10px',
                    '& .Mui-disabled .check-button': {
                        color: 'rgba(0, 0, 0, 0.26) !important'
                    },
                    '& .check-button': {
                        color: '#5173FF'
                    },
                    '& .icon-button-color': {
                        color: '#5173FF'
                    }
                },
                '& tr.MuiTableRow-root[mode="add"]': {
                    '& .MuiTableCell-root': {
                        padding: '10px'
                    }
                },
                '& td.MuiTableCell-paddingNone': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 'auto !important',
                },
                '& .MuiTableCell-alignLeft': {
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                }
            },
        },
        '& .Mui-checked': {
            '& .MuiSvgIcon-root': {
                fill: mainTheme.BrandColors.TemplatePrimary,
            }
        }
    },
    tableTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    tableHeading: {
        color: mainTheme.BrandColors.TemplatePrimary
    },
    chip: {
        marginLeft: '8px'
    },
    countFieldWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px 0'
    }
});

interface TableState<RowData extends object> {
    columns: Column<RowData>[];
    data: RowData[];
    isFormOpen?: boolean;
    selectedData?: RowData;
    isCustomPreviewOpen?: boolean;
}

export default function CustomFieldGroupsTab() {
    const classes = useStyles();

    const history = useHistory();

    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    const tab = searchParams.get('tab') || 'CustomFieldGroups';

    const [state, setState] = React.useState<TableState<CustomFieldGroup>>({
        columns: [
            {
                field: 'groupId',
                title: 'Group ID',
            },  
            {
                field: 'name',
                title: 'Group Name',
            },
            {
                field: 'description',
                title: 'Description',
            },
            {
                field: 'count',
                title: 'Fields',
                render: (data, type) => (
                    <div className={classes.countFieldWrapper}>
                        <IconButton
                            onClick={onClickCounts(data)}
                        >
                            <Badge color="primary" badgeContent={data.count || undefined}>
                                <LocalOfferOutlinedIcon color="primary" />
                            </Badge>
                        </IconButton>
                    </div>
                )
            },
            {
                field: 'areaOfLaw',
                title: 'Area of Law',
            },
            {
                field: 'location',
                title: 'Location',
            },
            {
                field: 'isActive',
                title: 'Active',
                render: (rowData) => (
                    <div>
                        {rowData.isActive && (
                            <CheckIcon />
                        )}
                    </div>
                )
            },
        ],
        data: [],
        isFormOpen: false,
        isCustomPreviewOpen: false,
    });

    const tableIcons: Icons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} className="check-button" />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} className="check-button" />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} className="check-button" />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} className="check-button" />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} className="check-button" />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} className="check-button" />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} className="check-button" />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} className="check-button" />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} className="check-button" />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} className="check-button" />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} className="check-button" />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} className="check-button" />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} className="check-button" />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} className="check-button" />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} className="check-button" />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} className="check-button" />),
    };

    const customFieldGroupQuery = useQuery<CustomFieldGroupData>(CustomFieldGroupQuery);

    const lookupNotification = useSubscription<EnquiryCustomFieldNotificationSubscriptionData>(EnquiryCustomFieldNotificationSubscription);

    useEffect(() => {

        if (lookupNotification.data && !lookupNotification.loading) {

            if (lookupNotification.data?.enquiryCustomFieldNotification.status) {

                const { enquiryCustomFieldNotification } = lookupNotification.data;

                showNotification('Success', enquiryCustomFieldNotification.message, 'info');

                customFieldGroupQuery.refetch();

            } else {
                showNotification('Failed', lookupNotification.data?.enquiryCustomFieldNotification.message || '', 'error');
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lookupNotification.data, lookupNotification.loading, lookupNotification.error, customFieldGroupQuery.refetch]);

    useEffect(() => {

        if (customFieldGroupQuery.data && !customFieldGroupQuery.loading) {
            setState((prevState) => {
                return {
                    ...prevState,
                    data: customFieldGroupQuery.data?.crm.customFieldGroup || []
                };
            });
        }

    }, [customFieldGroupQuery.data, customFieldGroupQuery.loading, customFieldGroupQuery.error]);

    // const handleDeletePriority = (oldData: any) => {
    //     client
    //         .mutate({
    //             mutation: DeletePriority,
    //             variables: {
    //                 guidId: oldData.guidId,
    //                 description: oldData.description,
    //             },
    //         })
    //         // tslint:disable-next-line: no-any
    //         .then((results: { data: any }) => {
    //             if (results.data) {
    //                 if (results.data.error !== undefined) {
    //                     showNotification( 'Failed to Delete Lead Source', results.data.error, 'error');
    //                 }
    //             }
    //         })
    //         // tslint:disable-next-line:no-any
    //         .catch((reason: any) => {
    //             showNotification('Failed to Delete Lead Source', reason, 'error');
    //         });
    // };

    const onClickCounts = (data: CustomFieldGroup) => () => {
        history.push({
            pathname: 'enquiryCustomFieldsConfiguration',
            search: `?tab=${tab}&tabKey=${data.guid}&areaOfLawGuid=${data.areaOfLawGuid}&locationGuid=${data.locationGuid}&groupId=${data.groupId}&title=${data.name}&description=${data.description}`,
        });
    };

    const deleteRowData = (oldData: CustomFieldGroup) => {

        client.mutate<DeleteEnquiryCustomFieldGroupMutationData, DeleteEnquiryCustomFieldGroupMutationParams>({
            mutation: DeleteEnquiryCustomFieldGroupMutation,
            variables: {
                guidId: oldData.guid
            }
        })
        .then((results) => { 
            if (results.data) {
                if (results.data.deleteEnquiryCustomFieldGroup.status && results.data.deleteEnquiryCustomFieldGroup.error === null) {
                    showNotification(null, 'Create custom field submitted', 'info');  

                    setState((prevState) => {
                        const tdata = [...prevState.data];
                        tdata.splice(tdata.indexOf(oldData), 1);
                        return {
                            ...prevState,
                            data: tdata,
                        };
                    });

                } else {
                    showNotification(null, 'Create custom field failed', 'error');
                }
            }            
        }) // tslint:disable-next-line:no-any
        .catch((reason) => { 
            showNotification(null, reason, 'error'); 
        });
    };

    // tslint:disable-next-line: no-any
    const onAdd = (event: any) => {
        setState((prevState) => {
            return {
                ...prevState,
                selectedData: undefined,
                isFormOpen: true,
            };
        });
    };

    // tslint:disable-next-line: no-any
    const onEdit = (event: any, data: CustomFieldGroup) => {
        setState((prevState) => {
            return {
                ...prevState,
                isFormOpen: true,
                selectedData: data
            };
        });
    };

    const onPreview = (event: React.MouseEvent, rowData: CustomFieldGroup) => {
        setState((prevState) => {
            return {
                ...prevState,
                isCustomPreviewOpen: true,
                selectedData: rowData
            };
        });
    };

    // const onRowClick = (event?: React.MouseEvent, rowData?: CustomFieldGroup, toggleDetailPanel?: (panelIndex?: number) => void) => {
    //     if (!rowData) {
    //         return;
    //     }

    //     history.push({
    //         pathname: 'enquiryCustomFieldsConfiguration',
    //         search: `?tab=${tab}&tabKey=${rowData.guid}&areaOfLawGuid=${rowData.areaOfLawGuid}&locationGuid=${rowData.locationGuid}&groupId=${rowData.groupId}&title=${rowData.name}&description=${rowData.description}`,
    //     });
    // };

    const onClose = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isFormOpen: false
            };
        });
    };

    const getTitle = (title: string, count: number) => {
        return (
            <div className={classes.tableTitle}>
                <Typography className={classes.tableHeading}>{title}</Typography>
                <Chip className={classes.chip} label={count} />
            </div>
        );
    };

    return (
        <div className={`${classes.root}`}>
            {state.isFormOpen && (
                <CustomFieldGroupDialog 
                    maxWidth="sm"
                    fullWidth={true}
                    open={state.isFormOpen}
                    onClose={onClose}
                    data={state.selectedData}
                />
            )}
            {state.isCustomPreviewOpen && (
                 <CustomFieldPreviewDialog 
                    open={state.isCustomPreviewOpen || false}
                    customFeildGroupData={state.selectedData}
                    title={state.selectedData?.name}
                    description={state.selectedData?.description}
                    maxWidth="sm"
                    fullWidth={true}
                    onClose={() => {
                        setState((prevState) => {
                            return {
                                ...prevState,
                                isCustomPreviewOpen: false,
                            };
                        });
                    }}
                />
            )}
            <MaterialTable<CustomFieldGroup>
                icons={tableIcons}
                isLoading={customFieldGroupQuery.loading}
                title={getTitle('Custom Field Groups', state.data.length)}
                columns={state.columns}
                data={state.data}
                // onRowClick={onRowClick}
                options={{
                    addRowPosition: 'first',
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30, 40, 50],
                    draggable: true,
                    actionsColumnIndex: -1,
                }}
                actions={[
                    {
                        icon: VisibilityIcon,
                        tooltip: 'Preview',
                        position: 'row',
                        onClick: onPreview
                    },
                    {
                        icon: AddBox,
                        tooltip: 'Add',
                        isFreeAction: true,
                        onClick: onAdd
                    },
                    {
                        icon: Edit,
                        tooltip: 'Edit',
                        position: 'row',
                        onClick: onEdit
                    }
                ]}
                editable={{
                    onRowDelete: (oldData) =>
                        // tslint:disable-next-line: no-any
                        new Promise((resolve: any) => {
                            setTimeout(() => {
                                resolve();
                                if (oldData) {
                                    // handleDeletePriority(oldData);
                                    deleteRowData(oldData);
                                }
                                // tslint:disable-next-line: align
                            }, 600);
                        }),
                }}
            />
        </div>
    );
}

type CustomFieldGroupData = {
    crm: CRM;
};

type CRM = {
    customFieldGroup: CustomFieldGroup[];
};

export type CustomFieldGroup = {
    guid:          string;
    groupId:       string;
    name:          string;
    description:   string;
    areaOfLawGuid: null;
    areaOfLaw:     string;
    locationGuid:  string;
    location:      string;
    isActive:      boolean;
    count:         number;
};

const CustomFieldGroupQuery = gql`
    query CustomFierldGroup {
        crm {
            customFieldGroup {
                guid
                groupId
                name
                description
                areaOfLawGuid
                areaOfLaw
                locationGuid
                location
                isActive
                count
            }
        }
    }
`;

type EnquiryCustomFieldNotificationSubscriptionData = {
    enquiryCustomFieldNotification: EnquiryCustomFieldNotification;
};

type EnquiryCustomFieldNotification = {
    customFieldGroupGuid: string | null;
    description:          string | null;
    guidId:               string;
    id:                   number;
    status:               boolean;
    message:              string;
}

const EnquiryCustomFieldNotificationSubscription = gql`
    subscription enquiryCustomFieldNotification {
        enquiryCustomFieldNotification {
            id
            customFieldGroupGuid
            description
            guidId
            status
            message
        }
    }
`;

type DeleteEnquiryCustomFieldGroupMutationParams = {
    guidId: string;
};

type DeleteEnquiryCustomFieldGroupMutationData = {
    deleteEnquiryCustomFieldGroup: DeleteEnquiryCustomFieldGroup;
}

type DeleteEnquiryCustomFieldGroup = {
    error:  null;
    status: boolean;
}

const DeleteEnquiryCustomFieldGroupMutation = gql`
    mutation DeleteEnquiryCustomFieldGroup($guidId: String) {
        deleteEnquiryCustomFieldGroup(guidId: $guidId){
            error
            status
        }
    }
`;