import React from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Checkbox, FormControlLabel, FormLabel, Grid, TextField, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { FormApi, ValidationErrors } from 'final-form';
import { checkIfIntialValuesIsEqualToValues } from '../../helpers/commonHelper';
import DialogBox from '../../Dashboard/Component/DialogBox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { CustomFieldTypeSelector, FieldType, FieldTypeReverseMapping } from '../selectors/CustomFieldTypeSelector';
import gql from 'graphql-tag';
import { client } from '../..';
import { showNotification } from '../../App';
import { IAutoCompleteItemV2 } from '../../typings/autoComplete';
import { CustomField } from '../tabs/customFieldGroups/CustomFieldGroupSelectedTab';
import { CustomFieldLookUpListItem, CustomFieldLookupListSelector } from '../selectors/CustomFieldLookupListSelector';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            textTransform: 'capitalize',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        button: {
            margin: theme.spacing(1),
            minWidth: '83px',
        },
        textField: {
            width: '100%',
        },
        editorClassName: {
            padding: '0 10px',
            minHeight: '100px',
        },
        wrapperClassName: {
            border: '1px solid #949494',
            display: 'flex',
            flexFlow: 'column-reverse',
        },
        toolbarClassName: {
            border: 0,
            // borderBottom: '1px solid #F1F1F1',
            margin: 0,
            padding: 0,
        },
        formLabel: {
            paddingBottom: '10px',
        },
    }),
);

interface CustomFieldGroupItemDialogProps extends Pick<DialogProps, 'open' | 'onClose' | 'maxWidth' | 'fullWidth'> {
    enquiryCustomFieldGroupGuid: string;
    groupId: string;
    count: number;
    data: CustomField | null;
    customFieldId: string[];
}

interface CustomFieldForm {
    isFormSubmit: boolean;
    name: string | null;
    description: string | null;
    instructions: string | null;
    customFieldType: IAutoCompleteItemV2<number> | null;
    customFieldLookupList: CustomFieldLookUpListItem | null;
    isMandatory: boolean;
    isActive: boolean;
    sequence: number;
    id: string | null;
}

export const CustomFieldGroupItemDialog: React.FC<CustomFieldGroupItemDialogProps> = (props) => {
    const classes = useStyles();

    const [showDiscard, setShowDiscard] = React.useState<boolean>(false);

    const generateId = () => {
        const paddedCount = String(props.count + 1).padStart(3, '0');
        return `${props.groupId}${paddedCount}`;
    };

    console.log('test', props.data?.customFieldType);

    const initialValues: CustomFieldForm = {
        isFormSubmit: false,
        customFieldLookupList: props.data && props.data.customFieldLookupListGuid && props.data.customFieldLookupListDescription ? { value: props.data.customFieldLookupListGuid, label: props.data.customFieldLookupListDescription } : null,
        customFieldType: props.data && props.data.customFieldType !== null ? { label: FieldTypeReverseMapping[props.data.customFieldType], value: props.data.customFieldType } : null,
        description: props.data && props.data.description ? props.data.description : null,
        instructions: props.data && props.data.instructions ? props.data.instructions : null,
        isActive: props.data && props.data.isAtive ? props.data.isAtive : true,
        isMandatory: props.data && props.data.isMandatory ? props.data.isMandatory : false,
        name: props.data && props.data.name ? props.data.name : null,
        sequence: props.data && props.data.sequence ? props.data.sequence : props.count + 1,
        id: props.data && props.data.customFieldId ? props.data.customFieldId : generateId(),
    };

    const handleClose = (form: FormApi<CustomFieldForm>, values: CustomFieldForm) => () => {

        const areValuesEqual = checkIfIntialValuesIsEqualToValues(initialValues, values);

        if (!areValuesEqual) {
            setShowDiscard(true);
        } else {
            if (props.onClose) {
                props.onClose({}, 'backdropClick');
            }
        }
    };

    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            if (props.onClose) {
                props.onClose({}, 'backdropClick');
            }
        }
        setShowDiscard(false);
    };

    const onSubmit = (values: CustomFieldForm, form: FormApi<CustomFieldForm, CustomFieldForm>) => {
        // tslint:disable-next-line: no-console

        if (!props.data) {

            client.mutate<CreateEnquiryCustomFieldData, CreateEnquiryCustomFieldParams>({
                mutation: CreateEnquiryCustomFieldMutation,
                variables: {
                    customFieldGroupGuid: props.enquiryCustomFieldGroupGuid,
                    id: values.id,
                    name: values.name,
                    description: values.description,
                    customFieldLookupListGuid: values.customFieldType?.value === FieldType['Lookup List'] && values.customFieldLookupList?.value ? values.customFieldLookupList.value : null,
                    customFieldType: values.customFieldType ? values.customFieldType.value : null,
                    instructions: values.instructions,
                    isMandatory: values.isMandatory,
                    isActive: values.isActive,
                    sequence: values.sequence
                }
            })
            .then((results) => { 
                if (results.data) {
                    if (results.data.createEnquiryCustomField.status && results.data.createEnquiryCustomField.error === null) {
                        showNotification(null, 'Create custom field submitted', 'info');  
                        if (props.onClose) {
                            props.onClose({}, 'backdropClick');
                        }
                    } else {
                        showNotification(null, 'Create custom field failed', 'error');
                    }
                }            
            }) // tslint:disable-next-line:no-any
            .catch((reason) => { 
                showNotification(null, reason, 'error'); 
            });
        } else {
            
            client.mutate<UpdateEnquiryCustomFieldMutationData, UpdateEnquiryCustomFieldMutationParams>({
                mutation: UpdateEnquiryCustomFieldMutation,
                variables: {
                    guidId: props.data.guid,
                    customFieldGroupGuid: props.enquiryCustomFieldGroupGuid,
                    id: values.id,
                    name: values.name,
                    description: values.description,
                    customFieldLookupListGuid: values.customFieldLookupList?.value || null,
                    customFieldType: values.customFieldType ? values.customFieldType.value : null,
                    instructions: values.instructions,
                    isMandatory: values.isMandatory,
                    isActive: values.isActive,
                    sequence: values.sequence
                }
            })
            .then((results) => { 
                if (results.data) {
                    if (results.data.updateEnquiryCustomField.status && results.data.updateEnquiryCustomField.error === null) {
                        showNotification(null, 'Create custom field submitted', 'info');  
                        if (props.onClose) {
                            props.onClose({}, 'backdropClick');
                        }
                    } else {
                        showNotification(null, 'Create custom field failed', 'error');
                    }
                }            
            }) // tslint:disable-next-line:no-any
            .catch((reason) => { 
                showNotification(null, reason, 'error'); 
            });

        }
        
    };

    const onValdiate = (values: CustomFieldForm) => {
        return undefined;
    };

    const onRequired = (value: CustomFieldForm) => (value ? undefined : 'Required');

    const onFieldRequiredError = (errors: ValidationErrors, form: FormApi<CustomFieldForm>, values: CustomFieldForm) => {
        // code here
        console.log('errors', errors);
    };
    
    const controlButtonComponent = (form: FormApi<CustomFieldForm>, submitting: boolean, pristine: boolean, values: CustomFieldForm) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                <div>
                    <Button
                        color="primary"
                        className={classes.button}
                        disabled={submitting || pristine}
                        onClick={() => {
                            form.change('isFormSubmit', true);
                            form.submit();
                        }}
                    >
                        Save
                    </Button>
                </div>
                <div>
                    <Button color="primary" type="button" className={classes.button} onClick={handleClose(form, values)}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };
    
    const validateId = (value: string) => {
        if (!value) {
            return 'Required';
        }
        
        if (!value.startsWith(props.groupId)) {
            return `ID must start with ${props.groupId}`;
        }

        if ((props.customFieldId.includes(value) && !props.data) || (props.data && props.data.customFieldId !== value && props.customFieldId.includes(value))) {
            return 'ID already exists';
        }

        return undefined;
    };

    const standarFields = (form: FormApi<CustomFieldForm>, submitting: boolean, pristine: boolean, values: CustomFieldForm) => {
        return (
            <Grid container={true} spacing={1}>
                <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                    <Field 
                        name={`id`} subscription={{ touched: true, error: true, value: true }} validate={validateId}>
                        {({ input, meta }) => (
                            <TextField
                                {...input}
                                className={classes.textField}
                                error={meta.error && meta.touched}
                                label="ID"
                                helperText={meta.error && meta.touched ? meta.error : ''}
                                required={true}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                    <Field 
                        name={`name`} subscription={{ touched: true, error: true, value: true }} validate={onRequired}>
                        {({ input, meta }) => (
                            <TextField
                                {...input}
                                className={classes.textField}
                                error={meta.error && meta.touched}
                                label="Name"
                                helperText={meta.error && meta.touched ? meta.error : ''}
                                required={true}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                    <Field name={`description`} subscription={{ touched: true, error: true, value: true }} validate={onRequired}>
                        {({ input, meta }) => (
                            <TextField
                                {...input}
                                className={classes.textField}
                                error={meta.error && meta.touched}
                                label="Description"
                                helperText={meta.error && meta.touched ? meta.error : ''}
                                required={true}
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                            <Field name={`customFieldType`} subscription={{ touched: true, error: true, value: true }} validate={onRequired}>
                                {({ input, meta }) => (
                                    <CustomFieldTypeSelector<number>
                                        {...input}
                                        label="Custom Field Type"
                                        name="office"
                                        required={true}
                                        value={input.value ? input.value : null}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? meta.error : ''}
                                        onSelection={(selection, name: string) =>
                                            input.onChange(selection)
                                        }
                                    />
                                )}
                            </Field>
                        </Grid>
                        {values.customFieldType && values.customFieldType.value === 8 && (
                            <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                                <Field 
                                    name={`customFieldLookupList`} 
                                    subscription={{ touched: true, error: true, value: true }}
                                    validate={values.customFieldType.value === 8 ? onRequired : undefined}
                                >
                                    {({ input, meta }) => (
                                        <CustomFieldLookupListSelector
                                            {...input}
                                            label="Lookup List"
                                            name="customFieldLookupList"
                                            required={true}
                                            value={input.value ? input.value : null}
                                            className={classes.textField}
                                            error={meta.error && meta.touched}
                                            onSelection={(selection, name: string) =>
                                                input.onChange(selection)
                                            }
                                        />
                                    )}
                                </Field>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                    <Field
                        name="isMandatory"
                        subscription={{touched: true, error: true, value: true}}
                    >                                            
                        {({ input, meta }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...input}
                                        checked={input.value}
                                        onChange={input.onChange}
                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                    />
                                }
                                label="Mandatory"
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                    <Field
                        name="isActive"
                        subscription={{touched: true, error: true, value: true}}
                    >                                            
                        {({ input, meta }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...input}
                                        checked={input.value}
                                        onChange={input.onChange}
                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                    />
                                }
                                label="Active"
                            />
                        )}
                    </Field>
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6} lg={12}>
                    <Field name={`instructions`} subscription={{ touched: true, error: true, value: true }}>
                        {({ input, meta }) => (
                            <>
                                <FormLabel component="legend" className={classes.formLabel}>Instructions</FormLabel>
                                <TinyEditor
                                    apiKey={'k74nddluc9fmtf75en31ew8mxzqcvovgpjkzomdtgeje7b0h'}
                                    value={input.value}
                                    // disabled={input.disabled}
                                    init={{
                                        height: 400,
                                        autoresize_min_height: '100%',
                                        autoresize_max_height: '100%',
                                        menubar: 'file edit view insert format tools table help custom',
                                        imagetools_cors_hosts: ['picsum.photos'],
                                        plugins:
                                            'table code advtable lists fullscreen image media link preview export pagebreak lists advlist checklist charmap directionality',
                                        toolbar:
                                            'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | ' +
                                            'forecolor backcolor | alignleft aligncenter alignright alignjustify indent outdent rtl ltr | bullist numlist checklist | ' +
                                            'emoticons image media table link hr charmap | preview | fullscreen | export pagebreak',
                                        // plugins: [
                                        //     'print preview paste importcss searchreplace autolink autosave save directionality code ' +
                                        //     'visualblocks visualchars fullscreen image link media template codesample table charmap ' +
                                        //     'hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools ' +
                                        //     'textpattern noneditable help charmap quickbars emoticons'
                                        // ],
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                        toolbar_sticky: true,
                                        autosave_ask_before_unload: true,
                                        autosave_interval: '30s',
                                        autosave_prefix: '{path}{query}-{id}-',
                                        autosave_restore_when_empty: false,
                                        autosave_retention: '2m',
                                        image_advtab: true,
                                        content_css: '//www.tiny.cloud/css/codepen.min.css',
                                        image_class_list: [
                                            { title: 'None', value: '' },
                                            { title: 'Some class', value: 'class-name' },
                                        ],
                                        importcss_append: true,
                                        // tslint:disable-next-line: typedef
                                        // file_picker_callback: function (callback, value, meta) {
                                        //     /* Provide file and text for the link dialog */
                                        //     if (meta.filetype === 'file') {
                                        //       callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                                        //     }

                                        //     /* Provide image and alt text for the image dialog */
                                        //     if (meta.filetype === 'image') {
                                        //       callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                                        //     }

                                        //     /* Provide alternative source and posted for the media dialog */
                                        //     if (meta.filetype === 'media') {
                                        //       callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                                        //     }
                                        // },
                                        templates: [
                                            {
                                                title: 'New Table',
                                                description: 'creates a new table',
                                                content:
                                                    '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
                                            },
                                            { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                                            {
                                                title: 'New list with dates',
                                                description: 'New List with dates',
                                                content:
                                                    '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
                                            },
                                        ],
                                        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                                        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                                        image_caption: true,
                                        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                        noneditable_noneditable_class: 'mceNonEditable',
                                        toolbar_mode: 'sliding',
                                        contextmenu: 'link image imagetools table',
                                    }}
                                    onEditorChange={(content: string) => {
                                        input.onChange(content);
                                    }}
                                />
                            </>
                        )}
                    </Field>
                </Grid>
            </Grid>
        );
    };
    
    return (
        <>
            <DialogBox
                title="Custom Field"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={showDiscard}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />

            <Form
                onSubmit={onSubmit}
                // mutators={{ ...arrayMutators }}
                initialValues={initialValues}
                validate={onValdiate}
                keepDirtyOnReinitialize={true}
                initialValuesEqual={() => true}
                // debug={(values) => {
                //     // tslint:disable-next-line: no-console
                //     console.log('Debug Values', values);
                // }}
                subscription={{ submitting: true, pristine: true, values: true, errors: true }}
                render={({ errors, handleSubmit, form, submitting, pristine, values }) => {
                    if (values.isFormSubmit) {
                        onFieldRequiredError(errors, form, values);
                    }

                    return (
                        <form 
                            onSubmit={handleSubmit}
                            id="customFieldGroupForm2"
                        >
                            <Dialog 
                                {...props}
                                onClose={handleClose(form, values)}
                            >
                                <DialogTitle className={classes.header}>Custom Field</DialogTitle>
                                <DialogContent>{standarFields(form, submitting, pristine, values)}</DialogContent>
                                <DialogActions>{controlButtonComponent(form, submitting, pristine, values)}</DialogActions>
                            </Dialog>
                        </form>
                    );
                }}
            />
        </>
    );
};

type CreateEnquiryCustomFieldParams = {
    customFieldGroupGuid?: string;
    id: string | null;
    name: string | null;
    description: string | null;
    instructions: string | null;
    customFieldType: number | null;
    customFieldLookupListGuid: string | null;
    isMandatory: boolean | null;
    isActive: boolean | null;
    sequence: number | null;
};

type CreateEnquiryCustomFieldData = {
    createEnquiryCustomField: CreateEnquiryCustomField;
};

type CreateEnquiryCustomField = {
    error:  null;
    status: boolean;
};

const CreateEnquiryCustomFieldMutation = gql`
    mutation CreateEnquiryCustomField (
        $customFieldGroupGuid: String,
        $id: String,
        $name: String,
        $description: String,
        $instructions: String,
        $customFieldType: Int,
        $customFieldLookupListGuid: String,
        $isMandatory: Boolean,
        $isActive: Boolean,
        $sequence: Int
    ) {
        createEnquiryCustomField(
            customFieldGroupGuid: $customFieldGroupGuid,
            id: $id,
            name: $name,
            description: $description,
            instructions: $instructions,
            customFieldType: $customFieldType,
            customFieldLookupListGuid: $customFieldLookupListGuid,
            isMandatory: $isMandatory,
            isActive: $isActive,
            sequence: $sequence
        ) {
            error
            status
        }
    }
`;

type UpdateEnquiryCustomFieldMutationParams = {
    customFieldGroupGuid?: string;
    guidId: string | null;
    id: string | null;
    name: string | null;
    description: string | null;
    instructions: string | null;
    customFieldType: number | null;
    customFieldLookupListGuid: string | null;
    isMandatory: boolean | null;
    isActive: boolean | null;
    sequence: number | null;
};

type UpdateEnquiryCustomFieldMutationData = {
    updateEnquiryCustomField: UpdateEnquiryCustomField;
}

type UpdateEnquiryCustomField = {
    error:  null;
    status: boolean;
}


const UpdateEnquiryCustomFieldMutation = gql`
    mutation UpdateEnquiryCustomField (
        $customFieldGroupGuid: String,
        $guidId: String,
        $id: String,
        $name: String,
        $description: String,
        $instructions: String,
        $customFieldType: Int,
        $customFieldLookupListGuid: String,
        $isMandatory: Boolean,
        $isActive: Boolean,
        $sequence: Int
    ) {
        updateEnquiryCustomField(
            customFieldGroupGuid: $customFieldGroupGuid,
            guidId: $guidId,
            id: $id,
            name: $name,
            description: $description,
            instructions: $instructions,
            customFieldType: $customFieldType,
            customFieldLookupListGuid: $customFieldLookupListGuid,
            isMandatory: $isMandatory,
            isActive: $isActive,
            sequence: $sequence
        ) {
            error
            status
        }
    }
`;