/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        width: '100%',
    },
  })
);

interface CustomFieldTypeSelectorProps<T> {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: CustomFieldTypeItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value: CustomFieldTypeItem | CustomFieldTypeItem[], name: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    disablePortal?: boolean;
    disableCloseOnSelect?: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
}

export const CustomFieldTypeSelector = <T, >(props: CustomFieldTypeSelectorProps<T>) => {

    const classes = useStyles();

    const options: CustomFieldTypeItem[] = [
        { value: 0, label: 'Text' },
        { value: 1, label: 'Multi Line' },
        { value: 2, label: 'Integer' },
        { value: 3, label: 'Number' },
        { value: 4, label: 'Currency' },
        { value: 5, label: 'Date' },
        { value: 6, label: 'Date/Time' },
        { value: 7, label: 'Yes/No'},
        { value: 8, label: 'Lookup List' },
        // { value: 9, label: 'File Upload' },
    ];

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        // tslint:disable-next-line: no-console
        console.log('onChange', value);
        if (props.onSelection) {
            props.onSelection(value, props.name ? props.name : '');
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            { props.multiple ? 
                (
                    <Autocomplete
                        value={props.value}
                        multiple={true}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={options}
                        onBlur={props.onBlur}
                        disableCloseOnSelect={props.disableCloseOnSelect}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: CustomFieldTypeItem) => option.label}     
                        getOptionSelected={(option, value) => value.value === option.value}               
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params)}
                                error={props.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        value={props.value}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={options}
                        disableCloseOnSelect={props.disableCloseOnSelect}
                        onBlur={props.onBlur}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: CustomFieldTypeItem) => option.label}      
                        getOptionSelected={(option, value) => value.value === option.value}   
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params)}
                                error={props.error}       
                                helperText={props.helperText}                          
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </div>
    );
};


export const FieldType = {
    Text: 0,
    'Multi Line': 1,
    Integer: 2,
    Number: 3,
    Currency: 4,
    Date: 5,
    'Date/Time': 6,
    'Yes/No': 7,
    'Lookup List': 8,
    // 'File Upload': 9
} as const;

export type FieldTypeKey = keyof typeof FieldType;

export type FieldTypeValue = typeof FieldType[FieldTypeKey];

export type CustomFieldTypeItem = {
    value: FieldTypeValue;
    label: FieldTypeKey;
}

export const FieldTypeReverseMapping: { [key: number]: FieldTypeKey } = Object.keys(FieldType)
    .reduce((acc, key) => {
        const value = FieldType[key as FieldTypeKey];
        acc[value] = key as FieldTypeKey;
        return acc;
    }, {} as { [key: number]: FieldTypeKey });