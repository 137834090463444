import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import SettingsIcon from '@material-ui/icons/Settings';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core';
import { RvLoader } from '../components/Loader';
import { FirmSettingConfigurationBanner } from '../firmSettingConfiguration/FirmSettingConfigurationBanner';
import { useHistory, useLocation } from 'react-router-dom';
import CustomFieldLookUpListsTab from './tabs/customFieldLookUpLists/CustomFieldLookUpListsTab';
import CustomFieldGroupsTab from './tabs/customFieldGroups/CustomFieldGroupsTab';
import CustomFieldLookUpListSelectedTab from './tabs/customFieldLookUpLists/CustomFieldLookUpListSelectedTab';
import CustomFieldGroupSelectedTab from './tabs/customFieldGroups/CustomFieldGroupSelectedTab';

const useStyles = makeStyles({
    root: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column',
    },
    wrapper: {
        flex: 1,
        display: 'flex',
        backgroundColor: '#fff',
        margin: '0 -15px',
        padding: '15px',
    },
    sidebar: {
        padding: '0 15px',
        maxWidth: '400px',
        width: '100%',
        position: 'relative',
        flex: 1,
        display: 'flex',
    },
    sidebarInner: {
        position: 'relative',
        flex: 1,
        // left: 0,
        // right: 0,
        // top: 0,
        // bottom: 0,
        // overflow: 'auto',
    },
    sidebarList: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        overflow: 'auto',
        paddingRight: '10px',
        '& .Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        },
        '& .Mui-selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }
    },
    content: {
        padding: '0 15px',
        flexGrow: 1,
        display: 'flex',
        flexFlow: 'column',
    },
    tabLoaderWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
    }
});

enum EnquiryCustomFieldsList {
    CustomFieldGroups = 'CustomFieldGroups',
    CustomFieldLookUpLists = 'CustomFieldLookUpLists',
}

type SettingName = {
    title: string;
    description: string;
    internalName: string;
}

interface EnquiryCustomFieldsPageState {
    selectedTable: EnquiryCustomFieldsList;
    tabs: SettingName[];
    isTabLoading: boolean;
}

interface EnquiryCustomFieldsPageProps {

}

export default function EnquiryCustomFieldsPage(props: EnquiryCustomFieldsPageProps) {

    const classes = useStyles();

    const location = useLocation();

    const history = useHistory();

    const selectedTab = location.pathname.split('/').pop();

    const searchParams = new URLSearchParams(location.search);

    const tabSelected = searchParams.get('tab') || EnquiryCustomFieldsList.CustomFieldGroups;
    const tabKey = searchParams.get('tabKey');
    
    const [pageState] = useState<EnquiryCustomFieldsPageState>({
        selectedTable: selectedTab && selectedTab !== 'enquiryCustomFieldsConfiguration' ? selectedTab as EnquiryCustomFieldsList : EnquiryCustomFieldsList.CustomFieldGroups,
        tabs: [
            {
                title: 'Custom Field Groups',
                description: 'Group of user defined custom fields',
                internalName: EnquiryCustomFieldsList.CustomFieldGroups,
            },
            {
                title: 'Custom Field Look Up Lists',
                description: 'Look up lists used for the validation of custom fields',
                internalName: EnquiryCustomFieldsList.CustomFieldLookUpLists,
            },
        ],
        isTabLoading: false,
    });

    const getIcon = (internalName: string) => {

        // tslint:disable-next-line: max-line-length
        if (internalName === EnquiryCustomFieldsList[EnquiryCustomFieldsList.CustomFieldGroups]) {
            return (
                <Avatar>
                    <SettingsIcon />
                </Avatar>
            );
        }

        if (internalName === EnquiryCustomFieldsList[EnquiryCustomFieldsList.CustomFieldLookUpLists]) {
            return (
                <Avatar>
                    <SettingsIcon />
                </Avatar>
            );
        }
                
        return (
            <Avatar>
                <SettingsIcon />
            </Avatar>
        );
    };

    const isHasAccess = (internalName: string): boolean => {
        
        // if (internalName === EnquiryCustomFieldsList[EnquiryCustomFieldsList.EmailConfiguration]) {
        //     return UserFeature.HasAccess(UserFeatureEnum.hasInnographyLink);
        // }

        // if (internalName === EnquiryCustomFieldsList[EnquiryCustomFieldsList.EnquiryDefaultConfiguration]) {
        //     return UserFeature.HasAccess(UserFeatureEnum.hasVisionSix);
        // }

        return true;
    };

    const onClickBack = () => {
        history.push('/configurationDashboard');
    };

    const onClickTab = (value: EnquiryCustomFieldsList) => () => {

        history.push({
            pathname: 'enquiryCustomFieldsConfiguration',
            search: `?tab=${value}`,
        });
        
        // setPageState({
        //     ...pageState,
        //     selectedTable: value
        // });

        // props.history.push(`/enquiryCustomFieldsConfiguration/${value}`);
    };
    
    return (
        <div className={classes.root}>
            <FirmSettingConfigurationBanner 
                title="Enquiry Custom Fields"
                onClickBack={onClickBack}
            />
            <div className={classes.wrapper}>
                {pageState.isTabLoading ? (
                    <div className={classes.tabLoaderWrapper}>
                        <RvLoader />
                    </div>
                ) : (
                    <>
                        <div className={classes.sidebar}>
                            <div className={classes.sidebarInner}>
                                <List className={classes.sidebarList}>
                                    {pageState.tabs.map((tab: SettingName) => isHasAccess(tab.internalName) && (
                                            <ListItem 
                                                key={tab.internalName}
                                                button={true} 
                                                onClick={onClickTab(tab.internalName as EnquiryCustomFieldsList)}
                                                selected={tabSelected === tab.internalName}
                                            >
                                                <ListItemAvatar>
                                                    {getIcon(tab.internalName)}
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={tab.title}
                                                    secondary={tab.description}
                                                />
                                            </ListItem>
                                        )
                                    )}
                                </List>
                            </div>
                        </div>
                        <div className={classes.content}>
                            {(tabSelected === EnquiryCustomFieldsList.CustomFieldGroups && !tabKey) && (
                                <CustomFieldGroupsTab />
                            )}
                            {(tabSelected === EnquiryCustomFieldsList.CustomFieldGroups && tabKey) && (
                                <CustomFieldGroupSelectedTab />
                            )}
                            {(tabSelected === EnquiryCustomFieldsList.CustomFieldLookUpLists && !tabKey) && (
                                <CustomFieldLookUpListsTab />
                            )}
                            {(tabSelected === EnquiryCustomFieldsList.CustomFieldLookUpLists && tabKey) && (
                                <CustomFieldLookUpListSelectedTab />
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}